/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />

$(() => {
    var loginWidget = $('.login-widget');
    if (loginWidget) {
        var getUsernameUrl = loginWidget.data('get-username-url');
        if (getUsernameUrl) {
            $.getJSON(getUsernameUrl, data => {
                var logOffUrl = loginWidget.data('log-off-url');
                if (data.hasOwnProperty('Welcome') && data.hasOwnProperty("Logout")) {
                    $('.login-widget').html(
                        '<i class="fa fa-user"></i>' +
                        '<span class="user-welcome"> ' + data.Welcome.Text + '</span>' +
                        '<span class="user-logout"><a href="' + logOffUrl + '">' + data.Logout.Text + '</a></span>'
                        );
                }
                else {
                    var logOnUrl = loginWidget.data('log-on-url');
                    var logInText = loginWidget.data('log-on-text');
                    $('.login-widget').html('<a href="' + logOnUrl + '"><i class="fa fa-user"></i> ' + logInText + '</a>');
                }
            });
        }
    }
});